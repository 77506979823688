import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Skill } from './../interfaces/skill';
import { Context } from './../interfaces/context';

@Injectable()
export class GroupsFirestoreService {

  constructor(public store: AngularFirestore) { }

  contexts(queryFn?: QueryFn) {
    return this.store.collection<Context>('feedbacks-contexts', queryFn)
  }

  competencies(queryFn?: QueryFn) {
    return this.store.collection<Skill>('feedbacks-competencies', queryFn)
  }
}
